import React, { useState } from "react";
import styled from "styled-components";

// social media icons
import { FiFacebook, FiInstagram } from "react-icons/fi";
import { FaFacebookF, FaMediumM, FaMedium } from "react-icons/fa";
// import {  } from "react-icons/ai";
import {
  RiInstagramFill,
  RiYoutubeFill,
  RiYoutubeLine,
  RiTwitterFill,
  RiTwitterLine,
  RiLinkedinFill,
  RiLinkedinLine,
} from "react-icons/ri";

const StyledFooter = styled.footer`
  background: var(--dark-blue);
  color: white;
  padding: 48px 32px;

  // SOCIAL
  .contact-links {
    padding: 0;
    max-width: var(--max-width);
    margin: 0 auto;
    position: relative;

    &::before,
    &::after {
      content: "";
      // 50px for each of 6 icons plus some extra padding
      width: calc((100% - 350px) / 2);
      position: absolute;
      border-top: 1px solid #cbd6e2;
      top: 50%;
    }

    &::before {
      right: 0;
    }
    &::after {
      left: 0;
    }

    & > ul {
      margin: 0;
      padding: 0;
      margin-top: 1em;
      width: 100%;
      position: relative;
      list-style: none;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    & a {
      color: var(--light-gray);
      text-decoration: none;
      margin: 0px 10px;

      &:hover {
        color: var(--light-blue);
      }
    }
  }

  // LEGAL
  .legal {
    text-align: center;
    margin-top: 32px;

    & > div {
      margin-bottom: 10px;
    }

    & ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    & a {
      color: white;
      text-decoration: underline;
      font-weight: bold;
      padding: 10px 20px;
      position: relative;
    }

    & a:not(:last-child)::before {
      content: "";
      width: 1px;
      background: #cbd6e2;
      position: absolute;
      right: 0;
      top: 30%;
      height: 40%;
    }
  }

  .logo {
    border: 1px solid white;
    padding: 10px;
    display: inline-block;
  }

  .copyright {
    color: #cbd6e2;
    margin-top: 15px;
  }
`;

const USE_FILLED_ICONS = true;

export default function Footer() {
  const [links] = useState([
    {
      // href: "https://www.facebook.com/hubspot",
      href: "https://www.facebook.com",
      classes: "",
      icon: USE_FILLED_ICONS ? FaFacebookF : FiFacebook,
    },
    {
      // href: "https://www.instagram.com/hubspot",
      href: "https://www.instagram.com",
      classes: "",
      icon: USE_FILLED_ICONS ? RiInstagramFill : FiInstagram,
    },
    {
      // href: "https://youtube.com/user/HubSpot",
      href: "https://youtube.com",
      classes: "",
      icon: USE_FILLED_ICONS ? RiYoutubeFill : RiYoutubeLine,
    },
    {
      // href: "https://twitter.com/HubSpot",
      href: "https://twitter.com",
      classes: "",
      icon: USE_FILLED_ICONS ? RiTwitterFill : RiTwitterLine,
    },
    {
      // href: "https://www.linkedin.com/company/hubspot",
      href: "https://www.linkedin.com",
      classes: "",
      icon: USE_FILLED_ICONS ? RiLinkedinFill : RiLinkedinLine,
    },
    // {
    //   href: "https://thinkgrowth.org",
    //   classes: "",
    //   icon: USE_FILLED_ICONS ? FaMedium : FaMediumM,
    // },
  ]);

  return (
    <StyledFooter>
      {/* links */}

      {/* social media */}
      <section className="contact-links">
        <ul>
          {links.map(({ href, classes, icon: Icon }) => (
            <li key={href}>
              <a href={href} target="_blank" className={classes}>
                <Icon />
              </a>
            </li>
          ))}
        </ul>
      </section>

      {/* extra */}
      <section className="legal">
        <div>
          <div className="logo">Moreland Connect LLC</div>
        </div>
        <div className="copyright">
          Copyright &copy; {new Date().getFullYear()} Moreland Connect LLC
        </div>
        <div>
          <ul>
            <li>
              <a>Legal Stuff</a>
              <a>Privacy Policy</a>
            </li>
          </ul>
        </div>
      </section>
    </StyledFooter>
  );
}
