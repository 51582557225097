import React from "react";
import styled from "styled-components";
import "normalize.css";
import Nav from "./Nav";
import Footer from "./Footer";
import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";

const ContentStyles = styled.div`
  background: var(--off-white);
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Nav />
      <ContentStyles>{children}</ContentStyles>
      <Footer />
    </>
  );
}
